/* that tab style are in  app.js */
.whatTellContainer {
  background-color: #f8f5ec;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.whatTellContainer .thecontent {
  height: 60%;
  width: 60%;
  margin: auto;
  text-align: center;
}
.whatTellContainer h1 {
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.whatTellContainer p {
  text-align: center;
  color: #444444;
  font-size: 18px;
  margin: 0;
  line-height: 35px;
  font-weight: 400;
  margin-bottom: 50px;
}
.whatTellContainer h5 {
  bottom: 20px;
  text-transform: uppercase;
  text-align: center;
}
.whatTellContainer h6 {
  text-align: center;
  bottom: 0px;
}
@media (max-width: 1024px) {
  .whatTellContainer .thecontent {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .whatTellContainer .thecontent h1 {
    width: 100%;
    font-size: 1.5rem;
  }
  .whatTellContainer .thecontent p {
    font-size: 1rem;
    line-height: 27px;
  }
}
