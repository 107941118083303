:root {
  --bright-blue: rgb(117 193 228);
  --btn-blue: rgb(60 83 94);
  --btnHover-blue: rgb(41 57 65);
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .checkout {
    margin-top: 2rem;
  }
}
.checkoutContainer .formGroup input {
  height: 50%;
}
.checkoutSmalContainer {
  margin-left: 0 !important;
  width: 100%;
}
.checkout input,
.checkout select,
.checkout button {
  margin-top: 1rem;
}
.rightorderColumn {
  padding-top: 20px;
  background-color: #f1f1f1 !important;
  width: 100%;
  border-left: 1px solid rgba(223, 223, 223, 1);
}
.leftFormColum .form-group {
  width: 50%;
}
/* meduim screen */
@media (max-width: 768px) {
  .rightorderColumn {
    padding-bottom: 200px;
  }
}

/* ================ */
.PaymentMethod .orderInfo .first,
.PaymentMethod .orderInfo .last {
  /* background-color: red; */
  font-size: 0.9rem !important;
}
/* ================= *
/* =============shoppping cart ===================== */
.imgcontainer {
  position: relative;
}
.logoCheckout {
  margin-left: 2rem !important;
  width: 6rem;
}
/* Styles for small screens */
@media (max-width: 576px) {
  .logoCheckout {
    margin-left: 2rem !important;
  }
}
/* Styles for medium screens */
@media (min-width: 768px) and (max-width: 991px) {
  .logoCheckout {
    margin-left: 3.5rem !important;
  }
}
/* Styles for large screens */
@media (min-width: 992px) {
  .logoCheckout {
    margin-left: 4rem !important;
  }
}

.productImg {
  width: 50px !important;
  height: 50px;
  border-radius: 15px;
  padding: 0px;
  border: 0.5px solid #d9d9d9;
  position: relative;
}
.productImg img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
}
.productInfo p {
  font-size: 0.7rem;
  margin-left: 5px;
}
.spanColor {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  border-radius: 100px;
  border: 2px solid #eee;
  margin-right: 5px;
  margin-bottom: 0px;
  margin-top: 3px;
}
.quantitCircle {
  position: absolute;
  background-color: #7f7f7f;
  color: #f7ffea;
  top: -9px;
  left: 48px;
  width: 20px !important;
  height: 20px !important;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
}
.currency {
  color: var(--secondary-gray);
  margin-right: 7px;
}
.Subtotal {
  margin-bottom: 0;
}
.shippingMethod {
  background-color: #f1f7fc;
  border: 1px solid var(--bright-blue);
}
.returnLink {
  color: var(--bright-blue);
}
.PaymentMethod {
  height: 100%;
  height: 80lvh;
}
.orderbtn,
.formbtn {
  background-color: var(--btn-blue) !important;
  color: white !important;
}
.orderbtn:hover,
.formbtn:hover {
  background-color: var(--btnHover-blue) !important;
}
.linkclass {
  color: var(--bright-blue);
}
.none {
  text-decoration: none;
}

.gray {
  color: var(--footer-links);
}
@media (max-width: 575.98px) {
  /* Styles to apply on small screens go here */
  .rightorderColumn {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
