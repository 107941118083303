/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.content-holder {
  min-height: 170vh;
}

/* start sidebar */
@media screen and (max-width: 767.5px) {
  .disable-bg-scroll {
    height: 100vh;
    overflow: hidden;
  }

  .shop-sidebar {
    width: 0 !important;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .shop-sidebar.show {
    width: 100% !important;
  }

  .shop-sidebar,
  .shop-sidebar .aside {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1025;
  }

  .shop-sidebar .aside {
    width: 60vw;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: auto;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }

  .shop-sidebar.show .aside {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .shop-sidebar .aside .form {
    overflow-y: auto;
  }
}

@media screen and (max-width: 475px) {
  .shop-sidebar .aside {
    width: 85vw;
  }
}

/* start filter */
.title span::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--main-black);
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.form-check-input {
  -webkit-transition: font-weight 0.5s ease;
  -o-transition: font-weight 0.5s ease;
  transition: font-weight 0.5s ease;
}

.form-check-input,
.form-check-input + label {
  cursor: pointer;
}

.form-check-input:not(:checked):hover + label,
.form-check-input:not(:checked) + label:hover {
  font-weight: bold !important;
}

.form-check-input:checked {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}

.form-check-input:checked + label {
  font-weight: bold;
}

/* start price filter */
.price-value {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 15px;
  width: 200px;
  height: 7px;
  background: #eee;
  border-radius: 5px;
  background-image: -o-linear-gradient(#222, #222);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#222),
    to(#222)
  );
  background-image: linear-gradient(#222, #222);
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: var(--yellow);
  cursor: pointer;
  -webkit-box-shadow: 0 0 2px 0 #555;
  box-shadow: 0 0 2px 0 #555;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: var(--yellow);
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: var(--yellow);
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  -ms-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: var(--yellow);
}

input[type="range"]::-moz-range-thumb:hover {
  background: var(--yellow);
}

input[type="range"]::-ms-thumb:hover {
  background: var(--yellow);
}

/* Input Track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
/* end price filter */
/* end filter */
/* end sidebar */

/* start pagination */
.page-link.active {
  color: white !important;
  background-color: var(--yellow) !important;
}

.page-link.disabled {
  pointer-events: none;
}
/* end pagination */