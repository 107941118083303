#account {
  margin-top: 4rem !important;
  margin-bottom: 8rem !important;
}
.hiddenScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hr */
.mt-10 {
  margin-top: 2.5rem !important;
}
.border-ms {
  margin-left: 0.2rem !important;
}
.title {
  padding: 1.2rem 0 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.title h6 {
  color: var(--footer-links);
}
.title h6:hover {
  color: var(--main-black);
}
.active {
  border-bottom: 2px solid var(--main-black) !important;
}
.active h6 {
  color: var(--main-black) !important;
  font-weight: 600;
}

@media (max-width: 576px) {
  .title h6 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.acountPage {
  padding-top: 3.5rem !important;
  max-width: 56rem !important;
}

/* ====== start acount info and password ========*/

/* 1-title */
.subTitle {
  font-weight: 600;
  margin-bottom: 3rem !important;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
@media (min-width: 640px) {
  .subTitle {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
@media (max-width: 640px) {
  .subTitle {
    margin-bottom: 1.2rem !important;
  }
}
/* 2-inputs */
.max-w-xl {
  max-width: 36rem !important;
}
.label label {
  color: var(--color-main);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input {
  color: var(--color-main) !important;
  border-radius: 1rem !important;
}

.input::-webkit-input-placeholder {
  color: #999 !important;
  font-size: 14px !important;
}

.input::-moz-placeholder {
  color: #999 !important;
  font-size: 14px !important;
}

.input:-ms-input-placeholder {
  color: #999 !important;
  font-size: 14px !important;
}

.input::-ms-input-placeholder {
  color: #999 !important;
  font-size: 14px !important;
}

.input::placeholder {
  color: #999 !important;
  font-size: 14px !important;
}

.passwordInputWrapper {
  position: relative;
}

.togglePasswordVisibilityButton {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.button {
  color: var(--white);
  border: 0;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 1.1rem !important;
  padding: 0.75rem 1rem !important;
  border-radius: 9999px;
}
@media (min-width: 640px) {
  .button {
    padding: 0.875rem 1.5rem !important;
    font-size: 1rem !important;
    line-height: 0.8rem !important;
  }
}

/* ====== end account info and password ===========*/

/* ====== start account order =======*/
:root {
  --green-color: rgb(34 197 94);
}
.cardHeader {
  background-color: rgb(248, 250, 252) !important;
}

.text-lg {
  font-weight: 600 !important;
  font-size: 1.3rem !important;
  line-height: 1.6rem !important;
}
.deleverDate {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
@media (min-width: 640px) {
  .deleverDate {
    margin-top: 0.5rem !important;
  }
}
.deliever {
  color: var(--green-color);
}
.btn-show-order {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-color: var(--footer-links) !important;
}

.btn-show-order:hover {
  border-color: var(--footer-links);
  background-color: rgb(243, 244, 246) !important;
}

@media (min-width: 640px) {
  .btn-show-orde {
    padding: 0 1.5rem;
  }
}

/* === 2-body ==== */
.cartBody {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.productImage {
  width: 4rem !important;
  height: 6rem !important;
  border-radius: 0.75rem;
}
@media (min-width: 640px) {
  .productImage {
    width: 6rem !important;
  }
}
.productImage img {
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  inset: 0px;
  color: transparent;
  max-width: 100%;
}
.productName {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.price {
  border: 2px solid;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-color: var(--green-color) !important;
  border-radius: 0.5rem;
  color: var(--green-color) !important;
}
.color {
  width: 1rem !important;
  height: 1rem !important;
  outline: 1px solid #dddada;
}
/* ====== end account order =======*/
