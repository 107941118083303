.cart-container {
  padding: 4rem 0;
  min-height: 80vh;
}
.pointer {
  cursor: pointer;
}
.item-img {
  width: 5rem;
  height: 5rem;
}
.circleColor {
  width: 1rem !important;
  height: 1rem !important;
  border: 1px solid rgb(222, 205, 205);
  margin-top: 0.3rem !important ;
}
.counter {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
  margin-top: -0.3rem;
}
@media (max-width: 767.98px) {
  .counter-value {
    width: auto;
  }
}
.item-name{
  margin-top: -2rem;
}