/* start modal style*/

.modal-open {
  overflow: hidden;
}
.smallText {
  font-size: 0.75rem; /* Adjust size as needed */
  color: #6b7280; /* Tailwind gray-600 color */
  text-align: center;
  margin-top: 1rem; /* Adjust margin as needed */
}
.cancel {
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  -webkit-transition: all 0.3s linear 0s;
  cursor: pointer;
}
.cancel:hover {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
}
.title {
  border-bottom: 2px solid #ebebeb;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 55px;
  font-weight: 700;
  margin: 0;
}
.title.active {
  color: var(--yellow);
  border-color: var(--yellow);
}
.appear{
    background-color: rgba(0, 0, 0, 0.5);
}
.button-navigate{
  margin-bottom:50px !important;
}



.or {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
}

.line {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0 10px;
}
.or span:nth-of-type(2){
  padding: 0 20px;
}

/* end login style*/

/* start shared style in login and register */


.label label {
  color: #6b6b6b;
}
.label label span {
  color: var(--red);
}
.input{
  color: var(--color-main);
}

.input::-webkit-input-placeholder {
  color: #999;
  font-size:14px
}

.input::-moz-placeholder {
  color: #999;
  font-size:14px
}

.input:-ms-input-placeholder {
  color: #999;
  font-size:14px
}

.input::-ms-input-placeholder {
  color: #999;
  font-size:14px
}

.input::placeholder {
  color: #999;
  font-size:14px
}

.group-button .button {
    color: var(--white);
    outline: none;
    width: 100%;
    border-radius: 2px;
    line-height: 40px;
    padding: 0;
    cursor: pointer;
    border: 0;
    -webkit-transition: all 0.24s linear 0s;
    -o-transition: all 0.24s linear 0s;
    transition: all 0.24s linear 0s;
}
.button-submit {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 10px;
}

.group-button .register{
    color: var(--color-main);

}

.passwordInputWrapper {
  position: relative;
}

.togglePasswordVisibilityButton {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
/* end shared style in login and register */


