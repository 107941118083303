/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.search-page {
  min-height: 80vh;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

@media (max-width: 767px) {
  input::-webkit-input-placeholder {
    font-size: 0.7rem !important;
  }
  input::-moz-placeholder {
    font-size: 0.7rem !important;
  }
  input:-ms-input-placeholder {
    font-size: 0.7rem !important;
  }
  input::-ms-input-placeholder {
    font-size: 0.7rem !important;
  }
  input::placeholder {
    font-size: 0.7rem !important;
  }
}

input,
.submit-holder {
  height: 2.5rem;
}

.add-to-cart-btn {
  font-size: 0.85rem;
  letter-spacing: 1px;
  width: 86%;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (min-width: 992px) {
  .add-to-cart-btn {
    opacity: 0;
    -webkit-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
  }

  .search-card:hover .add-to-cart-btn {
    opacity: 1;
  }
}

.extra-info {
  font-size: 0.85rem;
}